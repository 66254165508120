<template>
  <div class="detail-container">
    <div class="wrap">
      <div class="title">
        商品详情
      </div>
      <div class="content-box">
        <img class="image" :src="image.poster" v-for="image in item.detailImages">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Detail',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  .detail-container {
    width: 100%;
  }

  .title {
    margin: 40px 0;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
    font-weight: 700;
    color: #290b0b;
    border-left: 4px solid #290b0b;
  }

  .image {
    margin: 0 auto;
    width: 100%;
  }
</style>
