<template>
  <div class="banner-container">
    <div class="wrap">
      <img class="image" :src="image.href" alt="">
      <div class="goto-trolley" @click="gotoTrolley">
        <div class="box">
          <i class="el-icon-shopping-cart-full"></i>
          <span>去购物车结算</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Banner',
    data () {
      return {
        image: {
          href: require('@img/banner1.jpg')
        }
      }
    },
    methods: {
      getData () {
      },
      gotoTrolley () {
        this.$router.push({name: 'Trolley'})
      }
    },
    create () {
      this.getData()
    }
  }
</script>

<style scoped>
  .banner-container {
    width: 100%;
    margin-top: 64px;
    z-index: -1;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    margin: 0 auto;
    display: flex;
  }

  .image {
    float: left;
    width: calc(var(--inner-width) * 1px - 160px);
  }

  .goto-trolley {
    width: 160px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box {
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    cursor: pointer;
  }

  .box i:first-child {
    margin-left: 8px;
  }

  .box i:last-child {
    margin-right: 8px;
  }

</style>
