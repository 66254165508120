<template>
  <el-card class="item-box"
           shadow="hover">
    <img style="object-fit: contain" :src="item.poster">
    <div class="info-box">
      <span style="margin-left:10px; font-size: 24px; font-weight: 400; color: #1a1310;">{{item.title}}</span>
      <div class="bottom-box">
        <span style="margin-left:10px; font-size: 24px; font-weight: 400; color: #ec6002">￥ {{item.price}}</span>
        <span style="margin-left:10px; font-size: 18px; font-weight: 400; color: #a3a3a3;">成交{{item.doneDeal}}件</span>
      </div>
    </div>
  </el-card>
</template>

<script>
  export default {
    name: 'default-card',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>


  .item-box {
    width: 224px;
    /*height: 380px;*/
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .item-box /deep/ .el-card__body {
    padding: 0;
    height: 100%;
    box-sizing: border-box;
  }

  .item-box img {
    width: 224px;
    height: 224px;
  }

  .info-box {
    width: 100%;
    /*height: 100%;*/
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }

  .bottom-box {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .bottom-box span {
    display: block;
  }
</style>
