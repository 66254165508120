<template>
  <div class="category-container">
    <div class="wrap">
      <router-link class="item" v-for="item in items" :key="item.id" :to="{path:`/category?category=${item.id}`}">
        <div class="item-box">
          <img :src="item.poster">
          {{item.title}}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>

  import {Options} from '@/service/BaseService'
  import {IndexDivisionService} from '@/service/IndexService'

  const service = new IndexDivisionService()
  export default {
    name: 'Category',
    data () {
      return {
        service,
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.items = items
        }
        this.service.list(options)
      }
    }
    ,
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .category-container {
    width: 100%;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    display: flex;
    flex-wrap: wrap;
    padding: 48px 0;
  }

  .item-box {
    width: 300px;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 24px;
    border: 1px solid #e5e5e5;
  }

  .item-box img {
    width: 60px;
    height: 60px;
  }


</style>
