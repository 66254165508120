<template>
  <div class="profile-container">
    <div class="wrap">
      <div class="info-box">
        <span class="info">{{name}}</span>
        <el-button type="text" @click="logout">[注销]</el-button>
      </div>
      <dashboard></dashboard>
      <information></information>
    </div>
  </div>
</template>

<script>
  import Dashboard from '@/views/profile/components/dashboard'
  import Information from '@/views/profile/components/information'
  import {clearToken, getName, isLogin} from '@/utils/TokenUtils'

  export default {
    name: 'Profile',
    components: {Dashboard, Information},
    data () {
      return {
        name: getName()
      }
    },
    methods: {
      logout () {
        clearToken()
        this.$router.push({name: 'Login'})
      }
    },
    beforeRouteEnter: (to, from, next) => {
      if (!isLogin()) {
        clearToken()
        next({name: 'Login'})
      }
      next()
    }
  }
</script>

<style scoped>
  .profile-container {
    width: 100%;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .info-box {
    width: inherit;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    color: var(--main-theme-color);
    background-color: #363736;
  }

  .info-box > .info {
    font-size: 30px;
    font-weight: 700;
  }

</style>
