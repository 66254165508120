<template>
  <div class="address-container">
    <div class="breadcrumb-box">
      <el-breadcrumb class="breadcrumb-inner">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/profile">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>收货地址管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap">
      <el-card class="add-box">
        <div slot="header" class="clear-fix">
          <span style="font-size: 16px; font-weight: 700">收货地址</span>
        </div>
        <span style="display: block;color: #f47b5e;margin-bottom: 20px;">新增收货地址</span>
        <el-form label-position="left" label-width="100px" :model="item">
          <el-form-item label="详细地址">
            <el-input v-model="item.address"></el-input>
          </el-form-item>
          <el-form-item label="收货人姓名">
            <el-input v-model="item.contactName"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="item.contact"></el-input>
          </el-form-item>
        </el-form>
        <el-checkbox style="display: block; margin: 20px 0" v-model="item.isDefault" :true-label="1" :false-label="0">
          设为默认收货地址
        </el-checkbox>
        <el-button style="width: 120px; background-color: #ec3a04; color: white" @click="onSubmit">提交</el-button>
      </el-card>
      <el-table
        style="width: 100%; margin: 40px 0; box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)"
        border
        :data="items">
        <el-table-column
          prop="contactName"
          label="收货人"
          align="center"
          width="180">
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="地址">
        </el-table-column>
        <el-table-column
          prop="contact"
          align="center"
          width="160"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="isDefault"
          align="center"
          label="是否默认"
          width="80">
          <template slot-scope="{row}">
            <el-tag v-if="row.isDefault===1" type="success">默认</el-tag>
            <!--            {{row}}-->
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="120"
          align="center"
          label="操作">
          <template slot-scope="{row}">
            <el-button type="text" size="small" :disabled="row.isDefault===1" @click="setDefault(row.id)">设为默认
            </el-button>
            <el-button type="text" size="small" @click="onRemove(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import {clearToken, isLogin} from '@/utils/TokenUtils'
  import {AddressService} from '@/service/ProfileService'
  import {Options} from '@/service/BaseService'

  const service = new AddressService()
  export default {
    name: 'Address',
    data () {
      return {
        service,
        item: {
          address: '',
          contactName: '',
          contact: '',
          isDefault: false
        },
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.items = items
          items.forEach(e => console.log(e))
        }
        service.list(options)
      },
      onSubmit () {
        const options = new Options()
        options.data = this.item
        options.success = () => {
          this.getData()
        }
        service.save(options)
      },
      onRemove (id) {
        const options = new Options()
        options.data = [id]
        options.success = () => {
          this.getData()
        }
        service.delete(options)
      },
      setDefault (id) {
        const options = new Options()
        options.id = id
        options.success = () => {
          this.getData()
        }
        service.setDefault(options)
      }
    },
    created () {
      this.getData()
    },
    beforeRouteEnter: (to, from, next) => {
      if (!isLogin()) {
        clearToken()
        next({name: 'Login'})
      }
      next()
    }
  }
</script>

<style scoped>

  .address-container {
    width: 100%;
  }

  .breadcrumb-box {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
  }

  .breadcrumb-inner {
    margin: auto auto;
    width: calc(var(--inner-width) * 1px);
  }

  .wrap {
    margin: 20px auto 0;
    width: calc(var(--inner-width) * 1px);
  }

</style>
