<template>
  <div class="good-container">
    <div class="wrap">
      <div class="toolbar">
        <div class="orderBy">
          <ul>
            <li>综合排序</li>
            <li>价格</li>
            <li>销量</li>
          </ul>
        </div>
        <div class="total">共 <span>42</span>个商品</div>
      </div>
      <div class="good-box">
        <router-link
          v-for="item in items"
          :key="item.id"
          :to="{path: `/good/${item.id}`}">
          <good-card :item="item"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import {GoodItems} from '@/mock/Goods'
  import GoodCard from '@/components/good-card/default-card'

  export default {
    name: 'Good',
    components: {GoodCard},
    props: {
      items: []
    }
  }
</script>

<style scoped>
  .good-container {
    width: 100%;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }

  .orderBy {
    font-size: 24px;
  }

  .orderBy ul {
    width: 280px;
    display: flex;
    justify-content: space-between;
  }

  .good-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
</style>
