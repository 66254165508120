<template>
  <div class="recommend-container">
    <div class="wrap">
      <div class="title">
        <span>热门推荐</span>
        <div class="redirect" @click="redirectTo">
          <span>全部</span>
          <i class="el-icon-arrow-right"/>
        </div>
      </div>
      <div class="items">
        <div class="items-box">
          <good-card v-for="item in items" :key="item.id" :item="item" @click.native="gotoGood(item.id)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GoodCard from '@/components/good-card/default-card'
  import {RecommendService} from '@/service/IndexService'
  import {Options} from '@/service/BaseService'

  const service = new RecommendService()
  export default {
    name: 'Recommend',
    components: {GoodCard},
    data () {
      return {
        service,
        item: {},
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.items = items
        }
        this.service.list(options)
      },
      gotoGood (id) {
        console.log(id)
        const path = `/good/${id}`
        this.$router.push({path})
      },
      redirectTo () {
        this.$router.push({name: 'Category'})
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .recommend-container {
    width: 100%;
    background-color: #f6f6f6;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
  }

  .title {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
  }

  .title > span {
    border-left: 4px solid black;
    padding-left: 12px;
    font-size: 24px;
    font-weight: bolder;
  }

  .redirect {
    cursor: pointer;
    font-size: 14px;
  }

  .items {
    display: flex;
    justify-content: center;
  }

  .items-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    /*justify-content: start;*/
  }


</style>
