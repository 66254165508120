<template>
  <div class="information-container">
    <div class="wrap">
      <router-link class="box" v-for="item in items" :key="item.title" :to="item.component">
          <img class="icon" :src="item.image">
          <span>{{item.title}}</span>
      </router-link>
    </div>
  </div>


</template>

<script>
  export default {
    name: 'Information',
    data () {
      return {
        items: [
          {image: require('@img/location.png'), title: '收货地址管理', component: {name: 'Address'}},
          {image: require('@img/ticket.png'), title: '开票信息管理', component: {name: 'Invoice'}}
        ]
      }
    }
  }
</script>

<style scoped>
  .box {
    width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    cursor: pointer;
    /*box-shadow: 2px 0 12px rgba(0, 0, 0, 0.2);*/
  }

  .information-container {
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
  }

  .wrap {
    margin: auto auto;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon {
    width: 36px;
    height: 36px;
  }


</style>
