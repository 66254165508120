import {Message} from 'element-ui'
import {cloneDeep, merge} from 'lodash'
import HttpMethodEnum from '@/eunumeration/HttpMethodEnum'
import http from '@/utils/HttpRequest'

const request = http

class Options {
  url = ''
  params = {}
  data = {}
  showMessage = true
  success = function () {
  }
  failed = function () {
  }
}

class DefaultParams {
  language = undefined
  section = undefined
  species = undefined
  layout = undefined

  constructor (language, section, species, layout) {
    this.language = language
    this.section = section
    this.species = species
    this.layout = layout
  }
}

function afterSuccess (data, cb, showMessage = true) {
  if (showMessage && data.code === 401) {
  } else if (showMessage && data.code !== 0) {
    const type = data.code === 0 ? 'success' : 'error'
    Message({
      dangerouslyUseHTMLString: true,
      type,
      message: data.msg
    })
  }
  cb(data)
}

function afterFailed (error, cb) {
  console.log(error)
  const message = error.response ? error.response.status + '  ' + error.response.data.message : 'Internal Service Error'
  // Message.error(message)
  cb(error)
}

class BaseService {
  static adornUrl (url) {
    return request.adornUrl(url)
  }

  static adornUrls (prefix, resource) {
    return resource.reduce((pre, resource) => {
      pre[resource] = prefix + '/' + resource
      return pre
    }, {})
  }

  submit (options) {
    const {url, params, data, success, failed} = options
    request({
      url,
      method: HttpMethodEnum.POST,
      params: request.adornParams(params),
      data: request.adornData(data, false)
    }).then(({data}) => {
      afterSuccess(data, success, options.showMessage)
    }).catch(error => {
      afterFailed(error, failed)
    })
  }

  inquire (options) {
    const {url, success, failed, data, params} = options
    request({
      url,
      method: HttpMethodEnum.GET,
      params: request.adornParams(params),
      data: request.adornData(data, false)
    }).then(({data}) => {
      afterSuccess(data, success, options.showMessage)
    }).catch(error => {
      afterFailed(error, failed)
    })
  }
}

class ServiceImpl extends BaseService {
  resources = ['save', 'delete', 'update', 'all', 'list', 'page', 'first', 'info']
  urls = {}

  // INSERT
  save (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.save)
    super.submit(options)
  }

  // DELETE
  delete (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.delete)
    super.submit(options)
  }

  // UPDATE
  update (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.update)
    super.submit(options)
  }

  // SELECT
  all (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.all)
    super.inquire(options)
  }

  list (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.list)
    super.inquire(options)
  }

  page (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.page)
    super.inquire(options)
  }

  first (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(this.urls.first)
    super.inquire(options)
  }

  info (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    options.url = BaseService.adornUrl(`${this.urls.info}/${options.params.id}`)
    super.inquire(options)
  }
}

export {
  Options,
  DefaultParams,
  BaseService,
  ServiceImpl
}
