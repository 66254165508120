<template>
  <div class="navigate-container" @mouseleave="imgShow = false">
    <div class="wrap">
      <div class="greet">
        <span>{{greet}}</span>
      </div>
      <div class="search-box">
        <div class="icon">
          <i class="el-icon-search"></i>
        </div>
        <input type="text">
        <span @click="search">搜索</span>
      </div>
      <div class="navigate">
        <ul class="navigate-box">
          <router-link class="navigate-item"
                       v-for="item in items"
                       active-class="is-activated"
                       :key="item.title"
                       :to="item.href"
          >{{item.title}}
          </router-link>
          <router-link v-if="isLogin" :class="{'is-activated': isPathActivated}"
                       active-class="is-activated"
                       class="navigate-item"
                       to="/profile">个人中心
          </router-link>
          <router-link v-else active-class="is-activated" class="navigate-item" to="/login">登录</router-link>
          <li class="navigate-item">
            <div @mouseover="imgShow=true">
              <span style="position: relative">手机商城</span>
              <img v-show="imgShow" src="../icon/WEIXIN_MALL.jpg" class="avatar"
                   @mouseout="imgShow=false">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import {isLogin} from '@/utils/TokenUtils'

  export default {
    name: 'Navigate',
    watch: {
      '$route': 'reload'
    },
    computed: {
      isPathActivated: function () {
        return [
          '/address', '/invoice'
        ].some(e => e === this.$route.path)
      }
    },
    data () {
      return {
        greet: '米诺焊接， 欢迎您！',
        isLogin: false,
        imgShow: false,
        items: [
          {title: '首页', href: '/index'},
          {title: '商品分类', href: '/category'},
          {title: '购物车', href: '/trolley'}
        ]
      }
    },
    methods: {
      reload () {
        this.isLogin = isLogin()
      },
      getData () {
      },
      search () {
        console.log('search')
      }
    },
    created () {
      this.reload()
      this.getData()
    }
  }
</script>

<style scoped>
  .navigate-container {
    width: 100%;
    height: 64px;
    position: fixed;
    top: 0;
    background-color: #48423f;
    z-index: 9;
  }

  .wrap {
    margin: 0 auto;
    width: calc(var(--inner-width) * 1px);
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    color: white;
  }


  .navigate .navigate-box {
    width: 500px;
    display: flex;
    justify-content: space-between;
  }

  .search-box {
    width: 320px;
    height: 32px;
    display: flex;
    align-items: center;
  }

  .search-box .icon {
    height: 32px;
    width: 32px;
    background-color: #ffffff;
  }

  .search-box .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .search-box .icon i {
    font-weight: bolder;
    color: var(--main-theme-color);
  }

  .search-box input {
    height: 32px;
    border: none;
    outline: none;
  }

  .search-box span {
    display: inline-block;
    width: 56px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background-color: var(--main-theme-color);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
  }

  .search-box span::selection {
    color: #ffffff;
    background-color: var(--main-theme-color);
  }


  .navigate .navigate-item {
    margin-left: 32px;
  }

  .avatar {
    position: absolute;
    top: 50px;
  }

  .is-activated {
    color: var(--main-theme-color);
  }

  .is-activated:after {
    content: "";
    display: block;
    margin-top: 2px;
    width: 100%;
    height: 2px;
    background-color: var(--main-theme-color);
  }


</style>
