import {ServiceImpl} from '@/service/BaseService'

class IndexDivisionService extends ServiceImpl {
  prefix = '/api/mall/index/division'

  constructor () {
    super()
    if (!IndexDivisionService.instance) {
      IndexDivisionService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return IndexDivisionService.instance
  }
}

class RecommendService extends ServiceImpl {
  prefix = '/api/mall/index/recommend'

  constructor () {
    super()
    if (!RecommendService.instance) {
      RecommendService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return RecommendService.instance
  }
}

export {
  IndexDivisionService, RecommendService
}
