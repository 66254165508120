<template>
  <div class="items-container">
    <div class="wrap">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span>货物清单</span>
          <el-button style="float: right; padding: 3px 0"
                     type="text"
                     @click="$router.push({name:'Trolley'})">返回修改购物车
          </el-button>
        </div>
        <div class="item" v-for="item in items" :key="item.id">
          <el-image class="image" :src="item.poster"></el-image>
          <div class="info-box">
            <span>{{item.code}}</span>
            <span>{{item.title}}</span>
          </div>
          <div class="price">{{item.price}}</div>
          <div class="quantity">{{item.quantity}}</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Items',
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>
  .item {
    height: 100px;
    display: flex;
    justify-content: space-between;

    border: 1px solid #f5f5f5;
  }

  .image {
    width: 200px;
  }

  .image /deep/ img {
    width: 200px;
    height: 100%;
    object-fit: contain;
  }

  .info-box {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .price {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  .quantity {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
</style>
