<template>
  <div class="frame-container">
    <special-navigate></special-navigate>
    <banner></banner>
    <router-view></router-view>
    <special-footer></special-footer>
  </div>
</template>

<script>
  import SpecialNavigate from '@/views/frame/components/navigate'
  import Banner from '@/views/frame/components/banner'
  import SpecialFooter from '@/views/frame/components/footer'

  export default {
    name: "Frame",
    components: {SpecialNavigate, Banner, SpecialFooter}
  }
</script>

<style scoped>

</style>
