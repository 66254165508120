<template>
  <div class="gallery-box-container">
    <img class="image" :src="currentItem.poster" alt="">
    <div style="height: 24px;"></div>
    <el-carousel class="slice" type="card" height="170px" indicator-position="none" @change="sliceChangeHandle">
      <el-carousel-item v-for="item in items" :key="item.id">
        <img class="slice-image" :src="item.poster">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  export default {
    name: 'GalleryBox',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        currentItem: {}
      }
    },
    methods: {
      sliceChangeHandle (currentIndex, preIndex) {
        if (this.items.length > 0) {
          this.currentItem = this.items[currentIndex]
        }
      }
    }
  }
</script>

<style scoped>
  .gallery-box-container {
    width: 410px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*border: 1px solid #f1f1f1;*/
  }

  .image {
    width: 410px;
    height: 410px;
    object-fit: cover;
  }

  .slice {
    width: 100%;
  }

  .slice-image {
    width: 90px;
    height: 90px;
    object-fit: cover;
  }
</style>
