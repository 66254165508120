<template>
  <div class="address-container">
    <div class="wrap">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span>收货人信息</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="$router.push({name:'Address'})">新增收货地址
          </el-button>
        </div>
        <div class="item-box">
          <div class="item" v-for="item in items" :key="item.id">
            <span class="name" :class="{'selected': currentItem.id === item.id}" @click="setAddress(item)">{{item.contactName}}</span>
            <span class="title">{{item.contactName}}</span>
            <span class="address">{{item.address}}</span>
            <span class="contact">{{item.contact}}</span>
            <span class="default"><el-tag v-if="item.isDefault===1">默认地址</el-tag></span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Address',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    watch: {
      items: function (nv, ov) {
        if (nv.length <= 0) return
        const matched = nv.findIndex(e => e.isDefault === 1)
        if (matched !== -1) {
          this.currentItem = nv[matched]
        } else {
          this.currentItem = nv[0]
        }
      }
    },
    data () {
      return {
        currentItem: {}
      }
    },
    methods: {
      setAddress (item) {
        this.currentItem = item
        this.$emit('addressChange', item)
      }
    }
  }
</script>

<style scoped>
  .item {
    height: 44px;
    display: flex;
    align-items: center;
  }

  .item span {
    display: inline-block;
    margin-right: 20px;
  }

  .name {
    width: 120px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #dddddd;
    cursor: pointer;
  }

  .item-box {
    height: 100%;
    overflow-y: auto
  }

  .el-card /deep/ .el-card__body {
    height: 200px;
  }

  .selected {
    border: 2px solid var(--main-theme-thin-color);
  }
</style>
