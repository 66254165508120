<template>
  <div class="trolley-container">
    <div class="wrap">
      <div class="head">
        <span class="checkbox" style="width: 60px">
          <el-checkbox :value="selectedItems.length>=items.length"
                       @change="selectAll">全选</el-checkbox>
        </span>
        <span class="info" style="width: 320px;">商品信息</span>
        <span class="specification" style="width: 200px;">规格</span>
        <span class="price" style="width: 140px;">单价</span>
        <span class="quantity" style="width: 140px;">数量</span>
        <span class="summary" style="width: 140px;">小计</span>
        <span class="operation" style="width: 200px;">操作</span>
      </div>
      <div class="content">
        <trolley-item
          v-for="item in items"
          :item="item"
          :key="item.id"
          :isSelected="justifySelected(item)"
          @quantityChange="quantityChangeHandle"
          @selected="selectedHandle"
          @check="checkHandle"
          @delete="deleteHandle"
        ></trolley-item>
      </div>
      <div class="footer">
        <div class="checkbox">
          <el-checkbox :value="selectedItems.length>=items.length"
                       @change="selectAll">全选
          </el-checkbox>
          <el-button type="text" @click="deleteAllHandle">删除</el-button>
        </div>
        <div class="operation">
          <div class="info">
            <span>已选商品</span>
            <span class="quantity">{{count}}</span>
            <span>件合计（不含运费）</span>
          </div>
          <span class="price">￥{{sum}}</span>
          <div class="submit" :class="{'disabled': this.selectedItems.length<=0}" @click="settleHandle">结算</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TrolleyItem from '@/views/trolley/components/trolley-item'
  import {cloneDeep} from 'lodash'
  import {TrolleyService} from '@/service/TrolleyService'
  import {Options} from '@/service/BaseService'

  const service = new TrolleyService()
  export default {
    name: 'Trolley',
    components: {TrolleyItem},
    computed: {
      sum: function () {
        return parseFloat(this.selectedItems.reduce((pre, e) => {
          return pre += e.price * e.quantity
        }, 0.0)).toFixed(4)
      },
      count: function () {
        return this.selectedItems.reduce((pre, e) => pre += e.quantity, 0)
      }
    },
    data () {
      return {
        service,
        items: [],
        selectedItems: [],
        isSelectAll: false
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.items = items
        }
        this.service.list(options)
      },
      justifySelected (item) {
        return this.selectedItems.some(e => e.id === item.id)
      },
      selectAll (v) {
        this.isSelectAll = v
        this.selectedItems = v ? cloneDeep(this.items) : []
      },
      checkHandle (isChecked, item) {
        if (isChecked) {
          this.selectedItems.push(item)
        } else {
          const index = this.selectedItems.findIndex(e => e.id === item.id)
          this.selectedItems.splice(index, 1)
        }
      },
      quantityChangeHandle (quantity, entity) {
        let matched = this.items.find(e => e.id === entity.id)
        if (!matched) return
        matched.quantity = quantity
        matched.subTotal = quantity * matched.price
        const options = new Options()
        options.data = matched
        this.service.update(options)

        matched = this.selectedItems.find(e => e.id === entity.id)
        if (!matched) return
        matched.quantity = quantity
        matched.subTotal = quantity * matched.price
      },
      deleteHandle (item) {
        const options = new Options()
        options.data = [item.id]
        options.success = () => {
          this.getData()
          const index = this.selectedItems.findIndex(e => e.id === item.id)
          if (index !== -1) {
            this.selectedItems.splice(index, 1)
          }
        }
        this.service.delete(options)
      },
      deleteAllHandle () {
        const options = new Options()
        options.data = this.selectedItems.map(e => e.id)
        options.success = () => {
          this.selectedItems = []
          this.getData()
        }
        this.service.delete(options)
      },
      selectedHandle () {
      },
      settleHandle () {
        if (this.selectedItems.length <= 0) return
        const ids = this.selectedItems.map(e => e.id)
        window.localStorage.setItem('keys', JSON.stringify(ids))
        this.$router.push({name: 'Settle'})
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .trolley-container {
    width: 100%;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .head {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
  }

  .head span {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .head .checkbox {
    width: 120px;
  }

  .content {
    min-height: 300px;
  }

  .footer {
    display: flex;
    height: 40px;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
  }

  .footer > .checkbox {
    width: 120px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }


  .operation {
    width: 500px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .operation .info {
    width: 280px;
    display: flex;
    justify-content: space-evenly;
  }

  .operation .price {
    width: 100px;
    font-size: 16px;
    font-weight: 700;
    color: var(--main-theme-color);
  }

  .operation .submit {
    width: 120px;
    height: 100%;
    line-height: 40px;
    text-align: center;
    color: white;
    background-color: var(--main-theme-color);
    cursor: pointer;
  }

  .operation > .info > .quantity {
    font-size: 16px;
    font-weight: 700;
    color: var(--main-theme-color);
  }

  .disabled {
    background-color: #ffc099 !important;
    cursor: not-allowed;
  }
</style>
