<template>
    <div class="category-container">
        <div class="wrap">
            <ul class="category main-category">
                <li class="item" v-for="item in items"
                    :class="{'activated':item.id === currentCategory.id}"
                    :key="item.id"
                    @click="setCurrentCategory(item)">{{item.title}}
                </li>
            </ul>
            <el-divider/>
            <ul class="category sub-category">
                <li class="item" v-for="subItem in currentCategory.items"
                    :class="{'activated': subItem.id === currentSubCategory.id}"
                    :key="subItem.id"
                    @click="setCurrentSubCategory(subItem)">{{subItem.title}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'Category',
    props: {
      items: {
        type: Array,
        required: true
      },
      currentCategoryId: String,
      subCurrentCategoryId: String
    },
    watch: {
      'items': function (nv, ov) {
        if (nv.length <= 0) return

        if (this.currentCategoryId) {
          this.currentCategory = nv.find(e => e.id === parseInt(this.currentCategoryId)) || {}
        } else {
          this.currentCategory = nv[0]
        }
        if (this.currentCategory.items.length <= 0) return
        if (this.subCurrentCategoryId) {
          this.currentSubCategory = this.currentCategory.items.find(e => e.id === parseInt(this.subCurrentCategoryId))
        } else {
          this.currentSubCategory = this.currentCategory.items[0]
        }
      },
      'currentSubCategory': function (nv, ov) {
        this.onCurrentCategoryChange()
      }
    },
    data () {
      return {
        currentCategory: {},
        currentSubCategory: {}
      }
    },
    methods: {
      setCurrentCategory (category) {
        this.currentCategory = category
      },
      setCurrentSubCategory (subCategory) {
        this.currentSubCategory = subCategory
      },
      onCurrentCategoryChange () {
        this.$emit('change', this.currentSubCategory)
      }
    }
  }
</script>

<style scoped>
    .category-container {
        margin: 20px 0;
        width: 100%;
        background-color: #fff;
    }

    .wrap {
        width: calc(var(--inner-width) * 1px);
        margin: 0 auto;
    }

    .category {
        height: 32px;
        display: flex;
        align-items: center;
    }

    .item {
        min-width: 72px;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
    }

    /deep/ .el-divider {
        margin: 10px 0;
    }

    .activated {
        color: var(--main-theme-color);
    }
</style>
