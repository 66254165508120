<template>
  <div class="dashboard-container">
    <div class="wrap">
      <div class="title-box">
        <span>我的订单</span>
        <span @click="gotoOrder">查看全部订单 ></span>
      </div>
      <div class="board-box">
        <span>待付款（0）</span>
        <el-divider direction="vertical"></el-divider>
        <span>待发货（0）</span>
        <el-divider direction="vertical"></el-divider>
        <span>待收货（0）</span>
        <el-divider direction="vertical"></el-divider>
        <span>待评价（0）</span>
        <el-divider direction="vertical"></el-divider>
        <span>待售后/退款（0）</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DashBoard',
    methods: {
      gotoOrder () {
        this.$router.push({name: 'Order'})
      }
    }
  }
</script>

<style scoped>
  .dashboard-container {
    --box-height: 100;

    width: 100%;
    height: calc(var(--box-height) * 1px);
    border: 1px solid #DCDFE6;
  }

  .wrap {
    margin: 0 24px;
  }

  .title-box {
    height: calc(var(--box-height) / 2 * 1px);
    line-height: calc(var(--box-height) / 2 * 1px);
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #DCDFE6;
  }

  .title-box span:last-child {
    cursor: pointer;
  }

  .board-box {
    width: 100%;
    height: calc(var(--box-height) / 2 * 1px);
    line-height: calc(var(--box-height) / 2 * 1px);
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .board-box > /deep/ .el-divider {
    margin: auto auto;
    height: calc(var(--box-height) / 4 * 1px);
  }

  .board-box span {
    width: 100%;
    display: block;
  }
</style>
