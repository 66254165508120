import {BaseService, Options, ServiceImpl} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

class VerificationCodeService extends ServiceImpl {
  prefix = '/api/mall/auth/code'

  constructor () {
    super()
    if (!VerificationCodeService.instance) {
      VerificationCodeService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return VerificationCodeService.instance
  }

  contact (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    const path = this.prefix + '/' + options.contact
    options.url = BaseService.adornUrl(path)
    super.inquire(options)
  }
}

class AuthService extends ServiceImpl {
  prefix = '/api/mall/auth/auth'

  constructor () {
    super()
    if (!AuthService.instance) {
      AuthService.instanace = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return AuthService.instance
  }

  login (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    const path = this.prefix + '/login'
    options.url = BaseService.adornUrl(path)
    super.submit(options)
  }

}

export {
  VerificationCodeService,
  AuthService
}
