<template>
  <div class="login-container">
    <div class="wrap">
      <el-form :model="item" @keyup.native.enter.stop="login">
        <el-form-item prop="account">
          <el-input v-model="item.account" placeholder="请输入手机号码" clearable></el-input>
        </el-form-item>
        <el-form-item prop="verification">
          <el-input style="width: 50%" v-model="item.verificationCode" placeholder="请输入验证码" clearable></el-input>
          <el-button class="button-style" style="width: 50%;" type="primary" @click="getVerificationCode">获取验证码
          </el-button>
        </el-form-item>
      </el-form>
      <el-button-group style="width: 100%;">
        <el-button class="button-style" type="primary" @click="login" style="width: 100%">登录</el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>


  import {AuthService, VerificationCodeService} from '@/service/Auth'
  import {Options} from '@/service/BaseService'
  import {setToken} from '@/utils/TokenUtils'

  const service = new VerificationCodeService()
  const authService = new AuthService()

  export default {
    name: 'Login',
    data () {
      return {
        service,
        authService,
        pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
        item: {
          account: '',
          verificationCode: ''
        }
      }
    },
    methods: {
      contactVerify () {
        const account = this.item.account

        const conditions = [
          {expr: account.length <= 0, message: '请填写手机号码'},
          {expr: !this.pattern.test(account), message: '请填写合法手机号码'}
        ]
        for (let condition of conditions) {
          if (condition.expr) {
            this.$message({type: 'warning', message: condition.message})
            return false
          }
        }
        return true
      },
      verificationCodeVerify () {
        const content = this.item.verificationCode
        const valid = [typeof (content) === 'string', content.length > 0].every(e => e)
        if (!valid) {
          this.$message({type: 'warning', message: '请填写验证码'})
          return valid
        }
        return valid
      },
      getVerificationCode () {
        if (!this.contactVerify()) return
        const options = new Options()
        options.contact = this.item.account
        options.success = item => {
          if (item.code === 0) {
            this.$message({
              type: 'success',
              message: '验证码发送成功'
            })
          }
        }
        service.contact(options)
      },
      login () {
        if (!this.contactVerify()) return
        if (!this.verificationCodeVerify()) return

        const options = new Options()
        options.data = this.item
        options.success = ({item}) => {
          setToken(item)
          this.$router.push({name: 'Profile'})
        }
        authService.login(options)
      }
    },
    created () {
    }
  }
</script>

<style scoped>

  .login-container {
    margin-top: 3em;
    width: 100%;
  }

  .wrap {
    margin: auto auto;
    width: 500px;
    height: 300px;
  }

  .button-style {
    background-color: var(--main-theme-thin-color) !important;
    border-color: var(--main-theme-thin-color) !important;
  }

  .button-style:hover {
    background-color: var(--main-theme-color) !important;
    border-color: var(--main-theme-color) !important;
  }

</style>
