<template>
  <div class="settle-container">
    <div class="wrap">
      <span>填写并核对订单信息</span>
      <div class="inner-box">
        <e-address :items="address" @addressChange="addressChangeHandle"></e-address>
        <items :items="items"></items>
        <invoice :items="invoice" @invoiceChange="invoiceChangeHandle"></invoice>
      </div>
      <el-button class="submit" @click="submitOrder">提交订单</el-button>
    </div>
  </div>
</template>

<script>
  import EAddress from '@/views/settle/components/address'
  import Items from '@/views/settle/components/items'
  import Invoice from '@/views/settle/components/invoice'
  import {AddressService, InvoiceService} from '@/service/ProfileService'
  import {Options} from '@/service/BaseService'
  import {TrolleyService} from '@/service/TrolleyService'
  import {OrderService} from '@/service/OrderService'

  const orderService = new OrderService()
  const trolleyService = new TrolleyService()
  const addressService = new AddressService()
  const invoiceService = new InvoiceService()
  export default {
    name: 'index',
    components: {EAddress, Items, Invoice},
    data () {
      return {
        orderService,
        trolleyService,
        addressService,
        invoiceService,
        ids: [],
        items: [],
        address: [],
        invoice: [],
        currentAddress: {},
        currentInvoice: {}
      }
    },
    methods: {
      getAddress () {
        const options = new Options()
        options.success = ({items}) => {
          this.address = items
          const matched = items.findIndex(e => e.isDefault === 1)
          if (matched !== -1) {
            this.currentAddress = items[0]
            return
          }
          if (items.length > 0) {
            this.currentAddress = items[0]
          }
        }
        this.addressService.list(options)
      },
      getItems () {
        if (this.$route.params.type === 'NOW') {
          this.items = [this.$route.params.data]
        } else {
          const options = new Options()
          options.params = {ids: this.ids.join(',')}
          options.success = ({items}) => {
            this.items = items
            window.localStorage.removeItem('keys')
          }
          this.trolleyService.list(options)
        }
      },
      getInvoice () {
        const options = new Options()
        options.success = ({items}) => {
          this.invoice = items
          const matched = items.findIndex(e => e.isDefault === 1)
          if (matched !== -1) {
            this.currentInvoice = items[matched]
            return
          }
          if (items.length > 0) {
            this.currentInvoice = items[0]
          }
        }
        this.invoiceService.list(options)
      },
      addressChangeHandle (address) {
        this.currentAddress = address
      },
      invoiceChangeHandle (invoice) {
        this.currentInvoice = invoice
      },
      submitOrder () {
        const order = {
          total: this.items.reduce((pre, e) => pre += e.subTotal, 0),
          quantity: this.items.reduce((pre, e) => pre += e.quantity, 0),
          orderType: 1,
          deliveryAddressId: this.currentAddress.id,
          invoiceId: this.currentInvoice.id,
          items: this.items
        }
        const options = new Options()
        options.data = order
        options.success = data => {
          if (data.code === 0) {
            this.$router.push({name: 'Order'})
          }
        }
        this.orderService.save(options)
      }
    },
    created () {
      this.ids = JSON.parse(window.localStorage.getItem('keys'))
      this.getAddress()
      this.getItems()
      this.getInvoice()
    }
  }
</script>

<style scoped>
  .settle-container {
    width: 100%;
  }

  .wrap {
    margin: 60px auto;
    width: calc(var(--inner-width) * 1px);
  }

  .submit {
    float: right;
    margin: 20px;
    width: 100px;
    color: white;
    background-color: var(--main-theme-color);
  }

  .submit:hover {
    color: white;
    background-color: var(--main-theme-thin-color);
  }
</style>
