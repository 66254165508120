import {ServiceImpl} from '@/service/BaseService'

class TrolleyService extends ServiceImpl {
  prefix = '/api/mall/trolley'

  constructor () {
    super()
    if (!TrolleyService.instance) {
      TrolleyService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return TrolleyService.instance
  }
}

export {
  TrolleyService
}
