import {BaseService, Options, ServiceImpl} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

class AddressService extends ServiceImpl {
  prefix = '/api/mall/profile/address'

  constructor () {
    super()
    if (!AddressService.instance) {
      AddressService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return AddressService.instance
  }

  setDefault (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    const path = this.prefix + `/default/${options.id}`
    options.url = BaseService.adornUrl(path)
    super.inquire(options)
  }
}

class InvoiceService extends ServiceImpl {
  prefix = '/api/mall//profile/invoice'

  constructor () {
    super()
    if (!InvoiceService.instance) {
      InvoiceService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return InvoiceService.instance
  }

  setDefault (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    const path = this.prefix + `/default/${options.id}`
    options.url = BaseService.adornUrl(path)
    super.inquire(options)
  }
}

export {
  AddressService,
  InvoiceService
}
