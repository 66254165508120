<template>
  <div class="info-container">
    <div class="wrap">
      <gallery-box :items="item.images || []"></gallery-box>
      <buy-box :item="item || {}"></buy-box>
    </div>
  </div>
</template>

<script>
  import GalleryBox from '@/views/good/components/gallery-box'
  import BuyBox from '@/views/good/components/buy-box'

  export default {
    name: 'Info',
    components: {GalleryBox, BuyBox},
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>
  .info-container {
    height: 100%;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
  }
</style>
