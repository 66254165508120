<template>
  <div class="good-container">
    <div class="bread-crumb-box">
      <el-breadcrumb class="bread-crumb" separator=">">
        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: '/category' , query:{category: item.divisionId, subCategory: item.categoryId}}">商品
        </el-breadcrumb-item>
        <el-breadcrumb-item>商品详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap">
      <info :item="item"></info>
      <specification :item="item"></specification>
      <detail :item="item"></detail>
    </div>
  </div>
</template>

<script>
  import Info from '@/views/good/components/info'
  import Specification from '@/views/good/components/specification'
  import Detail from '@/views/good/components/detail'
  import {GoodService} from '@/service/GoodService'
  import {Options} from '@/service/BaseService'
  import BusEvent from '@/bus'
  import {TrolleyService} from '@/service/TrolleyService'
  import {isLogin} from '@/utils/TokenUtils'

  const service = new GoodService()
  const trolleyService = new TrolleyService()
  export default {
    name: 'Good',
    components: {Info, Specification, Detail},
    data () {
      return {
        service,
        trolleyService,
        item: {},
        category: {}
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params.id = this.$route.params.id
        options.success = ({item}) => {
          this.item = item
          console.log(this.item)
        }
        this.service.info(options)
      },
      trolleyHandle (...args) {
        if (!isLogin()) {
          this.$router.push({name: 'Login'})
        }
        const entity = this.makeTrolleyEntity(...args)
        const options = new Options()
        options.data = entity
        options.success = data => {
          this.$message({type: 'success', message: data.msg})
        }
        this.trolleyService.save(options)

      },
      immediatelyHandle (...args) {
        const entity = this.makeTrolleyEntity(...args)
        this.$router.push({
          name: 'Settle',
          params: {
            type: 'NOW',
            data: entity
          }
        })
      },
      makeTrolleyEntity (categoryItems, speciesItem, quantity) {
        const {id, poster} = categoryItems
        const {code, title, vipPrice} = speciesItem
        return {
          spuId: id, poster, code, title, price: vipPrice, quantity, subTotal: vipPrice * quantity, priceType: 2
        }
      }
    },
    created () {
      this.getData()
      this.$bus.$on(BusEvent.TROLLEY, this.trolleyHandle)
      this.$bus.$on(BusEvent.IMMEDIATELY, this.immediatelyHandle)
    },
    beforeDestroy () {
      this.$bus.$off(BusEvent.TROLLEY)
      this.$bus.$off(BusEvent.IMMEDIATELY)
    }
  }
</script>

<style scoped>

  .el-breadcrumb__item {
    font-size: 30px !important;
  }

  .bread-crumb-box {
    height: 60px;
    background-color: #f6f6f6;
  }

  .bread-crumb {
    width: calc(var(--inner-width) * 1px);
    line-height: 60px;
    margin: 0 auto;
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

</style>
