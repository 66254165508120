import {ServiceImpl} from '@/service/BaseService'

class OrderService extends ServiceImpl {
  prefix = '/api/mall/order'

  constructor () {
    super()
    if (!OrderService.instance) {
      OrderService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return OrderService.instance
  }
}

export {
  OrderService
}
