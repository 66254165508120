<template>
  <div class="order-container">
    <div class="breadcrumb">
      <el-breadcrumb class="inner-breadcrumb" separator=">">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/profile">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap">
      <order-panel :items="items" @change="onOrderTypeChange"></order-panel>
    </div>
  </div>
</template>

<script>
  import OrderPanel from '@/views/order/components/order-panel'
  import {clearToken, isLogin} from '@/utils/TokenUtils'
  import {Options} from '@/service/BaseService'
  import {OrderService} from '@/service/OrderService'

  const service = new OrderService()
  export default {
    name: 'Order',
    components: {OrderPanel},
    data () {
      return {
        service,
        items: [],
        orderType: 1
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.params = {orderType: this.orderType}
        options.success = ({items}) => {
          this.items = items || []
        }
        this.service.list(options)
      },
      onOrderTypeChange (v) {
        this.orderType = v
        this.getData()
      }
    },
    created () {
      this.getData()
    },
    beforeRouteEnter: (to, from, next) => {
      if (!isLogin()) {
        clearToken()
        next({name: 'Login'})
      }
      next()
    }
  }
</script>

<style scoped>

  .order-container {
    width: 100%;
  }

  .breadcrumb {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
  }

  .inner-breadcrumb {
    margin: 0 auto;
    width: calc(var(--inner-width) * 1px);
  }

  .wrap {
    margin: 0 auto;
    width: calc(var(--inner-width) * 1px);
  }


</style>
