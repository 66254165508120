import Vue from 'vue'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import router from '@/router'

import httpRequest from '@/utils/HttpRequest'
import '@/element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@font/iconfont.css'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import {isLogin} from '@/utils/TokenUtils'

Vue.use(VueVideoPlayer)
Vue.use(VueCookie)
Vue.prototype.$http = httpRequest
Vue.prototype.$isLogin = isLogin()
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
