<template>
  <div class="trolley-item-container">
    <div class="wrap">
      <div class="checkbox" style="width: 60px">
        <el-checkbox :value="isSelected" @change="changeHandle"></el-checkbox>
      </div>
      <div class="info" style="width: 320px">
        <img class="image" :src="item.poster">
        <span style="display: inline-block; margin-left: 20px">{{item.title}}</span>
      </div>
      <div class="species" style="width: 200px">
        <span>{{item.code}}</span>
        <span>{{item.title}}</span>
      </div>
      <div class="price" style="width: 140px; text-align: center">￥{{item.price}}</div>
      <div class="quantity" style="width: 140px; text-align: center">
        <el-input-number v-model="item.quantity" @change="quantityChangeHandle" :min="1" label="/PCS"></el-input-number>
      </div>
      <div class="summary" style="width: 140px; text-align: center">{{item.subTotal}}</div>
      <div class="operation" style="width: 200px; display: flex; justify-content: flex-end">
        <el-button type="text" @click="deleteHandle">删除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TrolleyItem',
    props: {
      item: {
        type: Object,
        required: true
      },
      isSelected: {
        type: Boolean,
        require: false
      }
    },
    methods: {
      quantityChangeHandle (value) {
        this.$set(this.item, 'quantity', value)
        this.$emit('quantityChange', value, this.item)
      },
      changeHandle (v) {
        this.$emit('check', v, this.item)
      },
      deleteHandle () {
        this.$emit('delete', this.item)
      }
    }
  }
</script>

<style scoped>
  .trolley-item-container {
    height: 108px;
    border-bottom: 1px solid #e5e5e5;
  }

  .wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }


  .image {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .checkbox {
    width: 80px;
  }

  .info {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .species {
    display: flex;
    flex-direction: column;
  }

</style>
