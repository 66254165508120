import Vue from 'vue'

const setToken = item => {
  Vue.prototype.$cookie.set('token', item.token)
  window.localStorage.setItem('dealerName', item.name)
}
const clearToken = () => {
  Vue.prototype.$cookie.delete('token')
  window.localStorage.removeItem('dealerName')
}
const getToken = () => {
  return Vue.prototype.$cookie.get('token')
}

const getName = () => {
  return window.localStorage.getItem('dealerName')
}

const isLogin = () => {
  const token = getToken()
  return token && /\S/.test(token)
}

export {
  setToken,
  clearToken,
  getToken,
  getName,
  isLogin
}
