<template>
  <div class="footer-container">
    <img :src="image.href"/>
    <div class="wrap">
      <ul class="info-container">
        <li v-for="item in items" :key="item.id">
          <span class="icon iconfont" :class="item.type"></span>
          <span>{{item.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data () {
      return {
        image: {
          href: require('@img/parner.jpg')
        },
        items: [
          {id: 1, type: 'icon-phone', title: '+86-18217181891'},
          {id: 2, type: 'icon-wechat', title: 'Minoo18217181891'},
          {id: 3, type: 'icon-email', title: 'marketing2@innoteccn.com'},
        ]
      }
    },
    methods: {
      getData () {
      }
    },
    create () {
      this.getData()
    }
  }
</script>

<style scoped>

  .footer-container {
    margin-top: 60px;
    position: relative;
  }

  .footer-container img {
    width: 100%;
    opacity: .6;
  }

  .wrap {
    width: 100%;
    position: absolute;
    top: 120px;
    font-size: 24px;
    font-weight: bolder;
  }

  .info-container {
    display: flex;
    justify-content: space-evenly;
  }

  .icon {
    --height: 48;
    margin-right: 20px;
    width: calc(var(--height) * 1px);
    height: calc(var(--height) * 1px);
    display: inline-block;
    line-height: calc(var(--height) * 1px);
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: normal;
    background-color: var(--main-theme-color);
    border-radius: calc(var(--height) / 2 * 1px);
  }
</style>
