<template>
  <div class="address-container">
    <div class="breadcrumb-box">
      <el-breadcrumb class="breadcrumb-inner">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item to="/profile">个人中心</el-breadcrumb-item>
        <el-breadcrumb-item>开票信息管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="wrap">
      <el-card class="add-box">
        <div slot="header" class="clear-fix">
          <span style="font-size: 16px; font-weight: 700">开票信息</span>
        </div>
        <span style="display: block;color: #f47b5e;margin-bottom: 20px;">新增开票地址</span>

        <el-radio-group v-model="itemType" size="large" fill="#f5711f" @change="item.invoiceType=itemType">
          <el-radio-button :label="0">个人</el-radio-button>
          <el-radio-button :label="1">企业普票</el-radio-button>
          <el-radio-button :label="2">企业增票</el-radio-button>
        </el-radio-group>

        <el-input
          style="margin: 10px 0"
          v-if="key!=='type'"
          v-for="(value, key, index) in entityForm[itemType]"
          v-model="item[key]"
          :placeholder="value"
          :key="index">
        </el-input>

        <el-checkbox style="display: block; margin: 20px 0"
                     v-model="item.isDefault"
                     :true-label="1"
                     :false-label="0"
        >设为默认收货地址
        </el-checkbox>
        <el-button style="width: 120px; background-color: #ec3a04; color: white" @click="onSubmit">提交</el-button>
      </el-card>
      <el-table
        style="width: 100%; margin: 40px 0; box-shadow: 0 2px 12px 0 rgba(0,0,0,.1)"
        border
        :data="items">
        <el-table-column
          prop="type"
          label="发票类型"
          align="center"
          width="180">
          <template slot-scope="{row}">
            <span v-if="row.invoiceType===0">个人发票</span>
            <span v-if="row.invoiceType===1">企业普票</span>
            <span v-if="row.invoiceType===2">企业增票</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="收货人"
          align="center"
          width="180">
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="地址">
        </el-table-column>
        <el-table-column
          prop="contact"
          align="center"
          width="120"
          label="联系方式">
        </el-table-column>
        <el-table-column
          prop="isDefault"
          align="center"
          width="80"
          label="是否默认">
          <template slot-scope="{row}">
            <el-tag v-if="row.isDefault===1" type="success">默认</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          width="200"
          align="center"
          label="操作">
          <template slot-scope="{row}">
            <el-button type="text" size="small" :disabled="row.isDefault===1" @click="setDefault(row.id)">设为默认
            </el-button>
            <el-button type="text" size="small" @click="onRemove(row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import {clearToken, isLogin} from '@/utils/TokenUtils'
  import {Options} from '@/service/BaseService'
  import {InvoiceService} from '@/service/ProfileService'

  const service = new InvoiceService()

  const personal = {name: '收票人姓名', contact: '联系方式', address: '地址'}
  const normalEnterprise = {name: '企业名称', contact: '纳税人识别号', address: '企业地址', registerNumber: '联系方式'}
  const appreciationEnterprise = {
    name: '企业名称',
    contact: '纳税人识别号',
    address: '企业地址',
    registerNumber: '联系方式',
    bankAccount: '银行账户',
    bank: '开户行'
  }
  const entityForm = {0: personal, 1: normalEnterprise, 2: appreciationEnterprise}

  export default {
    name: 'Invoice',
    data () {
      return {
        service,
        entityForm,
        itemType: 0,
        item: {
          invoiceType: 0,
          isDefault: 0,
          name: '',
          contact: '',
          address: '',
          registerNumber: '',
          bankAccount: '',
          bank: ''
        },
        items: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.items = items
          items.forEach(e => console.log(e))
        }
        service.list(options)
      },
      onSubmit () {
        const options = new Options()
        options.data = this.item
        options.success = data => {
          this.getData()
        }
        service.save(options)
      },
      onRemove (id) {
        const options = new Options()
        options.data = [id]
        options.success = () => {
          this.getData()
        }
        service.delete(options)
      },
      setDefault (id) {
        const options = new Options()
        options.id = id
        options.success = () => {
          this.getData()
        }
        service.setDefault(options)
      }
    },
    created () {
      this.getData()
    },
    beforeRouteEnter: (to, from, next) => {
      if (!isLogin()) {
        clearToken()
        next({name: 'Login'})
      }
      next()
    }
  }
</script>

<style scoped>

  .address-container {
    width: 100%;
  }

  .breadcrumb-box {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
  }

  .breadcrumb-inner {
    margin: auto auto;
    width: calc(var(--inner-width) * 1px);
  }

  .wrap {
    margin: 20px auto 0;
    width: calc(var(--inner-width) * 1px);
  }

</style>
