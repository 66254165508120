import {ServiceImpl} from '@/service/BaseService'

class GoodService extends ServiceImpl {
  prefix = '/api/mall/good'

  constructor () {
    super()
    if (!GoodService.instance) {
      GoodService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return GoodService.instance
  }
}

export {
  GoodService
}
