import {ServiceImpl} from '@/service/BaseService'

class DivisionService extends ServiceImpl {
  prefix = '/api/mall/division/category'

  constructor () {
    super()
    if (!DivisionService.instance) {
      DivisionService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return DivisionService.instance
  }
}

class SpuService extends ServiceImpl {
  prefix = '/api/mall/category/good'

  constructor () {
    super()
    if (!SpuService.instance) {
      SpuService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return SpuService.instance
  }
}

export {
  DivisionService, SpuService
}
