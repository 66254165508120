<template>
  <div class="terms-container" @click="onClicked">
    <div class="info">
      <div class="left-box">
        <el-checkbox v-model="checked"></el-checkbox>
<!--        <img class="icon" :src="icon">-->
        <span class="terms-name">{{name}}</span>
      </div>
      <div class="right-box" v-if="checked">
        <span>支付:</span><span class="price">{{price}}</span><span>元</span>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
  export default {
    name: 'terms',
    props: {
      checked: {
        type: Boolean, required: true
      },
      icon: String,
      name: {
        type: String, required: true
      },
      price: {
        type: Number, required: true
      }
    },
    methods: {
      onClicked () {
        this.$emit('click')
      }
    }
  }
</script>

<style scoped>
  .terms-container {
    height: 60px;
    margin-top: 24px;
    cursor: pointer;
  }

  .info {
    display: flex;
    justify-content: space-between;
  }

  .left-box {
    display: flex;
  }

  .left-box .icon {
    width: 32px;
    height: 32px;
    /*height: 100%;*/
    margin-left: 24px;
  }

  .left-box .terms-name {
    margin-left: 12px;
  }

  .right-box span {
  }

  .price {
    margin: 0 12px;
    font-size: 24px;
    font-weight: bolder;
    color: var(--main-theme-color);
  }
</style>
