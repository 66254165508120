<template>
  <div class="slice-container">
    <div class="wrap">
      <el-carousel height="600px">
        <el-carousel-item v-for="item in items" :key="item.id">
          <img class="image" :src="item.href" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Slice',
    data () {
      return {
        items: [
          {id: 1, href: require('@img/slice.jpg')},
          {id: 2, href: require('@img/slice.jpg')},
          {id: 3, href: require('@img/slice.jpg')},
          {id: 4, href: require('@img/slice.jpg')},
          {id: 5, href: require('@img/slice.jpg')}
        ]
      }
    },
    methods: {
      getData () {
      }
    },
    create () {
      this.getData()
    }
  }
</script>

<style scoped>
</style>
