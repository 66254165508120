import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '@/views/common/404'
import Login from '@/views/common/login'
import Frame from '@/views/frame/index'
import Index from '@/views/index/index'
import Category from '@/views/category/index'
import Good from '@/views/good/index'
import Trolley from '@/views/trolley/index'
import Profile from '@/views/profile/index'
import Order from '@/views/order/index'
import Address from '@/views/address/index'
import Invoice from '@/views/invoice/index'
import Settle from '@/views/settle/index'
import Cashier from '@/views/cashier/index'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  component: Frame,
  redirect: {name: 'Index'},
  children: [
    {path: '/404', component: NotFound, name: '404'},
    {path: '/login', component: Login, name: 'Login'},
    {path: '/index', component: Index, name: 'Index'},
    {path: '/category', component: Category, name: 'Category'},
    {path: '/good/:id', component: Good, name: 'Good'},
    {path: '/trolley', component: Trolley, name: 'Trolley'},
    {path: '/profile', component: Profile, name: 'Profile'},
    {path: '/order', component: Order, name: 'Order'},
    {path: '/address', component: Address, name: 'Address'},
    {path: '/invoice', component: Invoice, name: 'Invoice'},
    {path: '/settle', component: Settle, name: 'Settle'},
    {path: '/cashier', component: Cashier, name: 'Cashier'}
  ]
}]

const router = new VueRouter({routes})
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }
//
router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})

export default router
