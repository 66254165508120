<template>
  <div class="cashier-container">
    <div class="wrap">
      <el-card shadow="never">
        <div slot="header" class="clearfix">
          <span>收银台</span>
        </div>
        <terms v-for="(terms, index) in paymentTerms"
               :key="index" :name="terms.name"
               :icon="terms.icon"
               :price="order.total"
               :checked="terms.id===selectedTerms.id"
               @click="onClick(terms.id)"></terms>
        <div class="button">
          <el-button class="pay" @click="pay">立即付款</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import Terms from '@/views/cashier/components/terms'
  import {PayService} from '@/service/PayService'
  import {Options} from '@/service/BaseService'
  import {OrderService} from '@/service/OrderService'

  const service = new OrderService()
  const payService = new PayService()
  export default {
    name: 'Cashier',
    components: {
      Terms
    },
    data () {
      return {
        service,
        payService,
        paymentTerms: [
          // {id: 1, name: '微信支付', icon: require('@/views/cashier/icon/PAY-WEIXIN.png'), path: '/payment/weixin'},
          {id: 2, name: '支付宝支付', icon: require('@/views/cashier/icon/PAY-ALI.png'), path: '/pay/alipay'}
        ],
        order: {},
        selectedTerms: {}
      }
    },
    methods: {
      onClick (id) {
        this.selectedTerms = this.paymentTerms.find(e => e.id === parseInt(id)) || {}
      },
      refreshOrder () {
        const options = new Options()
        options.params = {id: this.order.id}
        options.success = ({item}) => {
          this.order = item
        }
        this.service.info(options)
      },
      pay () {
        console.log(this.order)
        const options = new Options()
        options.data = {out_trade_no: this.order.tradeOutId, subject: '米诺商城消费', total_amount: this.order.total}
        // options.data = {out_trade_no: this.order.tradeOutId, subject: '米诺商城消费', total_amount: 0.01}
        options.success = ({item}) => {
          const div = document.createElement('div')
          div.innerHTML = item
          document.body.appendChild(div)
          // document.forms[0].setAttribute('target', '_blank')
          document.forms[0].submit()
        }
        this.payService.pay(options)
      }
    },
    created () {
      this.order = this.$route.params.order || {}
      this.selectedTerms = this.paymentTerms[0]
      this.refreshOrder()
    },
    beforeRouteEnter (to, from, next) {
      if (from.path === '/order') {
        next()
      } else {
        next('/order')
      }
    }
  }
</script>

<style scoped>
  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }

  .button {
    display: flex;
    justify-content: flex-end;
  }

  .pay {
    width: 256px;
    height: 56px;
    color: white;
    font-size: 24px;
    background-color: var(--main-theme-color);
  }

  .pay:hover {
    color: white;
    background-color: var(--main-theme-color);
  }
</style>
