<template>
  <div class="order-panel-container">
    <div class="wrap">
      <div class="title-box">
        <span :class="{'is-activated': activeItem===-1}" @click="activeItem=-1">所有订单</span>
        <span v-for="(v, k, index) in orderTypes"
              :key="index"
              :class="{'is-activated': activeItem==k}"
              @click="activeItem=k">{{v}}</span>
      </div>
    </div>
    <div class="panel-box">
      <div v-if="items.length===0" class="empty-box">
        <div class="inner-box">
          <img :src="require('@img/no-order.png')" alt="">
          <span>您还没有相关订单</span>
        </div>
      </div>
      <el-card style="margin-top: 20px" shadow="never" v-for="item in items" :key="item.id">
        <div slot="header" class="clearfix">
          <div class="info">
            <span>{{item.createTime}}</span>
            <span>订单ID：{{item.id}}</span>
            <span>金额： {{item.total}}</span>
            <span>数量： {{item.quantity}}</span>
          </div>
          <el-button style="background-color: #eb6100;color: #feffff"
                     v-show="item.orderType === 1"
                     @click="gotoCashier(item)">付款
          </el-button>
        </div>
        <el-table :data="item.items">
          <el-table-column
            prop="title"
            label="名称"
            align="center">
          </el-table-column>
          <el-table-column
            prop="code"
            label="编号"
            width="180" align="center">
          </el-table-column>
          <el-table-column
            prop="price"
            label="单价"
            width="180" align="center">
          </el-table-column>
          <el-table-column
            prop="quantity"
            label="数量"
            width="180" align="center">
          </el-table-column>
          <el-table-column
            prop="subTotal"
            label="小计"
            width="180" align="center">
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrderPanel',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    watch: {
      activeItem: function (nv, ov) {
        this.$emit('change', nv)
      }
    },
    data () {
      return {
        orderTypes: {
          1: '待付款',
          2: '待发货',
          // 3: '待收货',
          9: '已完成',
        },
        activeItem: 1
      }
    },
    methods: {
      gotoCashier (order) {
        this.$router.push({name: 'Cashier', params: {order}})
      }
    }
  }
</script>

<style scoped>
  .order-panel-container {
    width: 100%;
  }

  .wrap {
  }

  .title-box {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid black;
  }

  .title-box > span {
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }

  .title-box > span:hover {
    color: white;
    background-color: var(--main-theme-color);
  }

  .is-activated {
    color: white;
    background-color: var(--main-theme-color);
  }

  .panel-box {
    min-height: 600px;
  }

  .empty-box {
    width: 100%;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-box > .inner-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .clearfix {
    display: flex;
    justify-content: space-between;
  }

  .clearfix .info {
    height: 40px;
    line-height: 40px;
  }

  .clearfix > span {
    margin-left: 30px;
  }

  .clearfix > span:first-child {
    margin-left: 0;
  }

</style>
