<template>
  <div class="index-container">
    <slice></slice>
    <div class="wrap">
      <category></category>
      <recommend></recommend>
    </div>
  </div>
</template>

<script>
  import Slice from '@/views/index/components/slice'
  import Category from '@/views/index/components/category'
  import Recommend from '@/views/index/components/recommend'

  export default {
    name: "Index",
    components: {Slice, Category, Recommend}
  }
</script>

<style scoped>
  .index-container {
  }

  .wrap {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }
</style>
