<template>
  <div class="buy-box-container">
    <div class="wrap">
      <span>{{item.title}}</span>
      <div class="price-box">
        <span class="prefix">价格：</span>
        <span class="price" :class="{'is-through':currentItem.vipPrice>0}">￥{{currentItem.normalPrice}}</span>
        <span class="price" v-if="currentItem.vipPrice>0">￥{{currentItem.vipPrice}}</span>
      </div>
      <div class="species-box">
        <span class="prefix">购买规格：</span>
        <ul class="species-items">
          <li class="species-item" :class="{'is-activated':species.id===currentItem.id}"
              v-for="species in item.species"
              :key="species.id" @click="setCurrentSpecies(species)">{{species.code}} {{species.title}}
          </li>
        </ul>
      </div>
      <div class="quantity">
        <span class="prefix">购买数量：</span>
        <el-input-number v-model="quantity" @change="handleChange" :min="1" label="描述文字"></el-input-number>
      </div>
      <div class="button-group">
        <div class="button put-in-trolley" @click="putInTrolley">加入购物车</div>
        <div class="button purchase" @click="purchaseImmediately">立即购买</div>
      </div>
    </div>
  </div>
</template>

<script>
  import BusEvent from '@/bus'

  export default {

    name: 'BuyBox',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    watch: {
      'item': function (nv, ov) {
        if (JSON.stringify(nv) === '{}') return
        const {species} = nv
        if (species.length <= 0) return
        this.currentItem = nv.species[0]
      }
    },
    data () {
      return {
        quantity: 1,
        currentItem: {}
      }
    },
    methods: {
      handleChange () {
      },
      setCurrentSpecies (species) {
        this.currentItem = species
      },

      putInTrolley () {
        this.$bus.$emit(BusEvent.TROLLEY, this.item, this.currentItem, this.quantity)
      },
      purchaseImmediately () {
        this.$bus.$emit(BusEvent.IMMEDIATELY, this.item, this.currentItem, this.quantity)
      }
    }
  }
</script>

<style scoped>
  .buy-box-container {
    height: 100%;
  }

  .wrap {
    float: right;
    width: 760px;
  }

  .wrap span {
    font-size: 30px;
    font-weight: 700;
    color: #1a1310;
    text-align: left;
    display: inline-block;
    width: 100%;
  }

  .price-box {
    margin-top: 24px;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff4ec;
    border-radius: 8px;
  }

  .wrap .prefix {
    width: 160px;
    font-size: 30px;
    font-weight: 400;
    color: #727272;
  }

  .price-box .price {
    font-size: 42px;
    font-weight: 800;
    color: #ec3b02;
  }

  .price-box .prefix {
    margin-left: 30px;
  }


  .species-box {
    display: flex;
    margin-top: 20px;
  }

  .species-box .prefix {
    margin-left: 0;
    width: 174px !important;
  }

  .species-items {
    width: 650px;
    display: flex;
    flex-wrap: wrap;
  }

  .species-item {
    padding: 0 20px;
    /*margin-right: 15px;*/
    margin-bottom: 15px;
    min-width: 190px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 22px;
    color: #606266;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    cursor: pointer;
  }

  .prefix {
    width: 160px;
  }

  .is-activated {
    border-color: var(--main-theme-color);
    color: var(--main-theme-color);
  }

  .is-through {
    text-decoration: line-through;
  }

  .button-group {
    margin-top: 40px;
    width: 400px;
    display: flex;
    justify-content: space-between;
  }

  .button {
    min-width: 180px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    border-radius: 4px;
    cursor: pointer;
  }


  .put-in-trolley,
  .put-in-trolley::selection {
    color: #ec6002;
    background-color: #fff4ec;
  }

  .purchase,
  .purchase::selection {
    color: #fff;
    background: linear-gradient(232deg, #f67823, #ec5a02);
  }


  .el-input-number /deep/ span,
  .el-input-number /deep/ .el-input input {
    font-size: 24px !important;
  }


</style>
