import {BaseService, Options, ServiceImpl} from '@/service/BaseService'
import {cloneDeep, merge} from 'lodash'

class PayService extends ServiceImpl {

  prefix = '/api/mall/pay'

  constructor () {
    super()
    if (!PayService.instance) {
      PayService.instance = this
    }
    this.urls = ServiceImpl.adornUrls(this.prefix, this.resources)
    return PayService.instance
  }

  pay (options = {}) {
    options = merge(new Options(), cloneDeep(options))
    const path = this.prefix + '/alipay'
    options.url = BaseService.adornUrl(path)
    super.submit(options)
  }
}

export {
  PayService
}
