<template>
  <div class="specification-container">
    <div class="wrap">
      <div class="title">
        商品规格
      </div>
      <div class="content-box">
        <div class="item"><span>品牌:</span><span class="short-content">{{item.brand}}</span></div>
        <el-divider direction="vertical"></el-divider>
        <div class="item"><span>类别:</span><span  class="short-content">{{item.categoryTitle}}</span></div>
        <el-divider direction="vertical"></el-divider>
        <div class="item"><span>商品编号:</span><span class="item-code"> {{ species.map(e=>e.code).join(' | ') }}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Specification',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    computed: {
      species: function () {
        if (JSON.stringify(this.item) === '{}') return []
        return this.item.species
      }
    }
  }
</script>

<style scoped>

  .specification-container {
    width: 100%;
  }

  .title {
    margin: 40px 0;
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 40px;
    font-weight: 700;
    color: #290b0b;
    border-left: 4px solid #290b0b;
  }

  .content-box {
    display: flex;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .item span {
    display: inline-block;
    font-size: 30px;
    font-weight: 400;
  }

  .item span:first-child {
    display: inline-block;
    padding-right: 20px;
    white-space: nowrap;
    flex-shrink: 0;
  }

  .short-content {
    white-space: nowrap;
  }
  .item-code {
    display: inline-block;
  }

  /deep/ .el-divider {
    height: 40px !important;
  }


</style>
