<template>
  <div class="category-container">
    <div class="bread-crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item style="font-size: 24px" :to="{ path: '/index' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item style="font-size: 24px" :to="{ path: '/category'}">商品分类</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <sub-category
      :items="categories"
      :current-category-id="$route.query.category"
      :sub-current-category-id="$route.query.subCategory"
      @change="onCategoryChange"/>
    <good :items="goods"/>
  </div>
</template>

<script>
  import SubCategory from '@/views/category/components/category'
  import Good from '@/views/category/components/good'
  import {DivisionService, SpuService} from '@/service/CategoryService'
  import {Options} from '@/service/BaseService'

  // #TODO: 数据的操作与获取都在这里处理。
  const service = new DivisionService()
  const spuService = new SpuService()
  export default {
    name: 'Category',
    components: {SubCategory, Good},
    data () {
      return {
        service,
        spuService,
        categories: [],
        goods: []
      }
    },
    methods: {
      getData () {
        const options = new Options()
        options.success = ({items}) => {
          this.categories = items
        }
        this.service.list(options)
      },
      onCategoryChange (item) {
        const options = new Options()
        options.params = {spuId: item.id}
        options.success = ({items}) => {
          this.goods = items
        }
        this.spuService.list(options)
      }
    },
    created () {
      this.getData()
    }
  }
</script>

<style scoped>
  .category-container {
    margin-top: 3em;
    width: 100%;
  }

  .el-breadcrumb__item {
    font-size: 30px !important;
  }

  .bread-crumb {
    width: calc(var(--inner-width) * 1px);
    margin: 0 auto;
  }


</style>
