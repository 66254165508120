<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>
  :root {
    --white: white;
    --main-theme-color: #EB6100;
    --title-theme-coilor: #027cc7;
    --radius: 10px;
    --board-title-color: #dddddd;
  }

  * {
    
  }

  /*消除所有图片下方的缝隙*/
  img {
    vertical-align: bottom;
    display: block;
  }




</style>
